import { useEffect, useReducer, createContext } from "react";
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import { history } from "../App";
import jwtDecode from "jwt-decode";
import axios from "axios";
import { JWTAuthConstants } from "constants";
import LoadingScreen from "components/LoadingScreen";
import config from "../config";

const initialAuthState = {
  isAuthenticated: false,
  isInitialised: false,
  user: null,
  users: [],
  scans: [],
  companies: [],
};

const initSentry = (dsn) => {
  Sentry.init({
    dsn,
    integrations: [
      new Integrations.BrowserTracing({
        routingInstrumentation: Sentry.reactRouterV5Instrumentation(history),
      }),
    ],
    tracesSampleRate: 1.0,
  });
  return;
};

const isValidToken = (accessToken) => {
  if (!accessToken) {
    return false;
  }

  const decoded = jwtDecode(accessToken);
  const currentTime = Date.now() / 1000;

  return !decoded.exp || decoded.exp > currentTime;
};

const setSession = (accessToken) => {
  if (accessToken) {
    localStorage.setItem("accessToken", accessToken);
    axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
  } else {
    localStorage.removeItem("accessToken");
    localStorage.removeItem("mission_filter");
    delete axios.defaults.headers.common.Authorization;
  }
};

const {
  INITIALISE,
  ADDUSER,
  RMUSER,
  UPDUSER,
  LOGIN,
  LOGOUT,
  REGISTER,
  ADDTOCPMPANY,
  RMCOMPANY,
  ADDCOMPANY,
  UPDCOMPANY,
  DELETEFROMCOMPANY,
} = JWTAuthConstants;

const reducer = (state, action) => {
  switch (action.type) {
    case INITIALISE:
      const { isAuthenticated, user, users, scans, companies } = action.payload;

      return {
        ...state,
        isAuthenticated,
        isInitialised: true,
        user,
        users,
        scans,
        companies,
      };
    case ADDUSER: {
      const { users , companies} = action.payload;

      return {
        ...state,
        users,
        companies
      };
    }
    case UPDUSER: {
      const { user, companies} = action.payload;

      return {
        ...state,
        isInitialised: true,
        user: user._id === state.user._id ? user : state.user,
        users: state.users.map(el => {
          if(el._id === user._id) {
            user.avatar = el.avatar;
            user.company = user.company._id;
            return user;
          }
          else {
            return el;
          }
        }),
        companies
      };
    }
    case RMUSER: {
      const { user, users , companies} = action.payload;
      if (state.user?._id === user._id) {
        setSession();
        return {
          ...state,
          users,
          isAuthenticated: false,
          user: null,
          scans: null,
          companies
        };
      }

      return {
        ...state,
        users,
        isInitialised: true,
        companies
      };
    }
    case LOGIN: {
      const { user } = action.payload;
      return {
        ...state,
        isAuthenticated: true,
        user,
      };
    }
    case LOGOUT: {
      return {
        ...state,
        isAuthenticated: false,
        user: null,
        users: null,
        scans: null,
        companies: null,
      };
    }
    case REGISTER: {
      const { user } = action.payload;

      return {
        ...state,
        isAuthenticated: true,
        user,
      };
    }
    case ADDCOMPANY: {
      const { company , users} = action.payload;
      return {
        ...state,
        companies: state.companies.concat(company),
        users
      };
    }
    case UPDCOMPANY: {
      const { companies , users  } = action.payload;
      return {
        ...state,
        companies,
        users
      };
    }
    case ADDTOCPMPANY: {
      const { company } = action.payload;
      return {
        ...state,
        companies: state.companies.map((item) => {
          if (item._id === company._id) {
            return company;
          }
          return item;
        }),
      };
    }
    case RMCOMPANY: {
      const { company } = action.payload;
      return {
        ...state,
        companies: state.companies.filter((item) => item._id !== company._id),
      };
    }
    case DELETEFROMCOMPANY: {
      const { user } = action.payload;

      const u = state.companies
        .find((item) => item._id === user.company)
        ?.users.find((item) => item._id === user._id);
      if (!u) return state;
      return {
        ...state,
        user:
          state.user?._id === user._id
            ? {
              ...state.user,
              company: undefined,
            }
            : state.user,
        users: state.users.map((item) => {
          if (item._id === u._id) {
            return {
              ...item,
              company: undefined,
            };
          }
          return item;
        }),
      };
    }
    default:
      return { ...state };
  }
};

const AuthContext = createContext({
  ...initialAuthState,
  method: "JWT",
  login: () => Promise.resolve(),
  logout: () => { },
  register: () => Promise.resolve(),
  updUser: () => { },
  addUser: () => Promise.resolve(),
  rmUser: () => Promise.resolve(),
});

export const AuthProvider = Sentry.withProfiler(({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialAuthState);

  const login = async (email, password) => {
    // Sentry.addBreadcrumb({
    //   category: "auth",
    //   message: "Login user",
    //   level: Sentry.Severity.Info,
    //   data: {
    //     email,
    //     password,
    //   },
    // });
    try {
      const response = await axios.post(`${config.api_host}/api/auth/login`, {
        email,
        password,
      });

      const { accessToken, user } = response.data;

      initSentry(
        config.SENTRY
      );

      setSession(accessToken);

      await initialise();

      dispatch({
        type: LOGIN,
        payload: {
          user,
        },
      });

      Sentry.addBreadcrumb({
        category: "auth",
        message: "Authenticated user " + email,
        level: Sentry.Severity.Info,
        data: {
          user,
        },
      });
      return user;
    } catch (error) {
      Sentry.captureException(error.message);
      return Promise.reject(error);
    }
  };

  const logout = () => {
    setSession(null);
    dispatch({ type: LOGOUT });
  };

  const register = async (
    email,
    firstName,
    lastName,
    birth,
    username,
    password
  ) => {
    try {
      const response = await axios.post(
        `${config.api_host}/api/auth/registration`,
        {
          email,
          firstName,
          lastName,
          birth,
          username,
          password,
        }
      );
      const { accessToken, user } = response.data;

      window.localStorage.setItem("accessToken", accessToken);

      dispatch({
        type: "REGISTER",
        payload: {
          user,
        },
      });

      Sentry.addBreadcrumb({
        category: "auth",
        message: "Register user " + email,
        level: Sentry.Severity.Info,
        data: {
          user,
        },
      });
    } catch (e) {
      Sentry.captureException(e);
      return Promise.reject(e.message);
    }
  };

  const updUser = async (formData, id) => {
    try {
      const user = await axios.put(
        `${config.api_host}/api/account/update/${id}`,
        formData
      );
      const responseCompanies = await axios.get(
        `${config.api_host}/api/company`
      );
      const companies = responseCompanies.data;

      await initialise();

      Sentry.addBreadcrumb({
        category: "edit",
        message: "Update user " + id,
        level: Sentry.Severity.Info,
        data: {
          user: user.data,
          companies
        },
      });

      return user;
    } catch (e) {
      Sentry.captureException(e);
      return Promise.reject(new Error(e.message));
    }
  };

  const addUser = async (formData) => {
    try {
      const user = await axios.post(
        `${config.api_host}/api/auth/registration`,
        formData
      );
      const responseCompanies = await axios.get(
        `${config.api_host}/api/company`
      );
      const companies = responseCompanies.data;

      dispatch({
        type: "ADDUSER",
        payload: {
          users: state.users.concat(user.data),
          companies
        },
      });

      await initialise();

      Sentry.addBreadcrumb({
        category: "edit",
        message: "Add user.",
        level: Sentry.Severity.Info,
        data: {
          user: user.data,
        },
      });
      return user;
    } catch (error) {
      Sentry.captureException(error);
      return Promise.reject(new Error(error.message));
    }
  };

  const rmUser = async (id) => {
    try {
      const user = await axios.delete(`${config.api_host}/api/account/${id}`);
      const responseUsers = await axios.get(`${config.api_host}/api/account`);
      const responseCompanies = await axios.get(
        `${config.api_host}/api/company`
      );
      responseUsers.data?.map((item => {
        if(item._id === user._id){
          user.avatar = item.avatar
        }
      }))
      const companies = responseCompanies.data;
      dispatch({
        type: "RMUSER",
        payload: {
          user: user.data,
          users: responseUsers.data,
          companies
        },
      });

      Sentry.addBreadcrumb({
        category: "edit",
        message: "Delete user.",
        level: Sentry.Severity.Info,
        data: {
          id,
        },
      });

      return user;
    } catch (e) {
      Sentry.captureException(e);
      return Promise.reject(new Error(e.message));
    }
  };

  const addCompany = async (formData) => {
    try {
      const company = await axios.post(
        `${config.api_host}/api/company/new`,
        formData
      );
      const responseUsers = await axios.get(`${config.api_host}/api/account`);
      
      dispatch({
        type: "ADDCOMPANY",
        payload: {
          company: company.data,
          users: responseUsers.data
        },
      });

      Sentry.addBreadcrumb({
        category: "edit",
        message: "Add company.",
        level: Sentry.Severity.Info,
        data: {
          company: company.data,
        },
      });

      return true;
    } catch (e) {
      Sentry.captureException(e);
    }
  };

  const addToCpmpany = async (userId, companyId) => {
    try {
      const company = await axios.post(
        `${config.api_host}/api/account/add-to-company`,
        {
          company: companyId,
          user: userId,
        }
      );

      dispatch({
        type: ADDTOCPMPANY,
        payload: {
          company: company.data.company,
        },
      });

      Sentry.addBreadcrumb({
        category: "edit",
        message: "Add to company.",
        level: Sentry.Severity.Info,
        data: {
          company: company.data.company,
        },
      });

      return company.data.company;
    } catch (e) {
      Sentry.captureException(e);
      return Promise.reject(new Error(e.message));
    }
  };

  const deleteFromCompany = async (id) => {
    try {
      const u = await axios.delete(
        `${config.api_host}/api/account/delete-from-company/${id}`
      );

      if (state.user?._id === u._id) {
        await initialise();
      } else {
        state.users?.map((item => {
          if(item._id === u.data._id){
            u.data.avatar = item.avatar
          }
        }))
        dispatch({
          type: "DELETEFROMCOMPANY",
          payload: {
            user: u.data,
          },
        });
      }

      Sentry.addBreadcrumb({
        category: "edit",
        message: "Delete from company.",
        level: Sentry.Severity.Info,
        data: {
          user: u.data,
        },
      });

      return u;
    } catch (e) {
      Sentry.captureException(e);
      return Promise.reject(new Error(e.message));
    }
  };

  const changeRole = async (id, role) => {
    try {
      const u = await axios.put(
        `${config.api_host}/api/account/change-role/${id}`,
        {
          role,
        }
      );

      await initialise();

      Sentry.addBreadcrumb({
        category: "edit",
        message: "Change user role.",
        level: Sentry.Severity.Info,
        data: {
          id,
          role,
        },
      });

      return u;
    } catch (e) {
      Sentry.captureException(e);
      return Promise.reject(new Error(e.message));
    }
  };

  const changeStatus = async (id, status) => {
    try {
      const user = await axios.post(
        `${config.api_host}/api/account/status/${id}`,
        {
          status,
        }
      );

      state.users?.forEach((item => {
        if(item._id === user._id){
          user.avatar = item.avatar;
        }
      }))
      dispatch({
        type: "UPDUSER",
        payload: {
          user: user.data,
          users: state.users,
          companies: state.companies,
        },
      });

      Sentry.addBreadcrumb({
        category: "edit",
        message: "Change user status.",
        level: Sentry.Severity.Info,
        data: {
          id,
          status,
        },
      });

      return user;
    } catch (e) {
      Sentry.captureException(e);
      return Promise.reject(new Error(e.message));
    }
  };

  const rmCompany = async (id) => {
    try {
      const company = await axios.delete(
        `${config.api_host}/api/company/${id}`
      );

      dispatch({
        type: "RMCOMPANY",
        payload: {
          company: company.data,
        },
      });

      Sentry.addBreadcrumb({
        category: "edit",
        message: "Delete company.",
        level: Sentry.Severity.Info,
        data: {
          company: company.data,
        },
      });

      return company;
    } catch (e) {
      Sentry.captureException(e);
      return Promise.reject(new Error(e.message));
    }
  };

  const accessCompany = async (id, assign) => {
    try {
      const company = await axios.post(
        `${config.url}/api/company/app-access/${id}`,
        {
          assign,
        }
      );

      await initialise();

      Sentry.addBreadcrumb({
        category: "edit",
        message: "Change access company.",
        level: Sentry.Severity.Info,
        data: {
          id,
          assign,
        },
      });

      return company;
    } catch (e) {
      Sentry.captureException(e);
      return Promise.reject(new Error(e.message));
    }
  };

  const updCompany = async (data, id) => {
    try {
      const company = await axios.put(
        `${config.api_host}/api/company/${id}`,
        data
      );
      const responseUsers = await axios.get(`${config.api_host}/api/account`);
      const responseCompanies = await axios.get(
        `${config.api_host}/api/company`
      );
      const companies = responseCompanies.data;
      
      dispatch({
        type: UPDCOMPANY,
        payload: {
          users: responseUsers.data,
          companies
        },
      });

      Sentry.addBreadcrumb({
        category: "edit",
        message: "Update company.",
        level: Sentry.Severity.Info,
        data: {
          company: company.data,
        },
      });

      return company;
    } catch (e) {
      Sentry.captureException(e);
      return Promise.reject(new Error(e.message));
    }
  };

  const initialise = async () => {
    try {
      const accessToken = window.localStorage.getItem("accessToken");

      if (accessToken && isValidToken(accessToken)) {
        setSession(accessToken);

        // GET CONFIG
        const responseConfig = await axios.get(`${config.api_host}/api/account/config`);
        const { GOOGLEMAP, SENTRY, SCAN_SERVING_HOST } = responseConfig.data.Keys;

        config.GOOGLEMAPKEY = GOOGLEMAP; 
        config.cloud_front_host = `https://${SCAN_SERVING_HOST}`;
        
        initSentry(
          SENTRY
        );

        //GET CURRENT USER
        const response = await axios.get(`${config.api_host}/api/auth/user`);
        const user = response.data;

        //GET SCANS LIST
        const responseScan = await axios.get(`${config.api_host}/api/scan`);
        const scans = responseScan.data;

        //GET USERS LIST
        const responseUsers = await axios.get(`${config.api_host}/api/account`);
        const users = responseUsers.data;
        users?.map((item => {
          if(item._id === user._id){
            user.avatar = item.avatar
          }
        }))
        //GET COMPANIES LIST
        const responseCompanies = await axios.get(
          `${config.api_host}/api/company`
        );
        const companies = responseCompanies.data;

        dispatch({
          type: INITIALISE,
          payload: {
            isAuthenticated: true,
            user,
            users,
            scans,
            companies,
          },
        });

        Sentry.addBreadcrumb({
          category: "edit",
          message: "Initialization.",
          level: Sentry.Severity.Info,
          data: {
            user,
            scans,
          },
        });
      } else {
        dispatch({
          type: INITIALISE,
          payload: {
            isAuthenticated: false,
            user: null,
            users: [],
            scans: null,
          },
        });
      }
    } catch (e) {
      Sentry.captureException(e);
      dispatch({
        type: INITIALISE,
        payload: {
          isAuthenticated: false,
          user: null,
          scans: null,
        },
      });
    }
  };

  useEffect(() => {
    initialise();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!state.isInitialised) {
    return <LoadingScreen />;
  }

  return (
    <AuthContext.Provider
      value={{
        ...state,
        method: "JWT",
        login,
        logout,
        register,
        initialise,
        updUser,
        addUser,
        rmUser,
        rmCompany,
        accessCompany,
        changeRole,
        addToCpmpany,
        addCompany,
        updCompany,
        changeStatus,
        deleteFromCompany,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
});

export default AuthContext;
