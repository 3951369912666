import React from 'react'
import ReactDOM from 'react-dom'

const ModalWrapper = ({
  title,
  show,
  toggleShow,
  display,
  children,
  width = 367,
  styles,
  disableHeader,
}) => {
  if (!show) {
    return <></>
  }
  const handleClose = () => toggleShow(false)
  return ReactDOM.createPortal(
    <div className="ModalWrapper">
      <div className="ModalWrapper__bg" onClick={handleClose} />
      <div
        className="ModalWrapper__content"
        style={{
          maxWidth: width,
          width: '96%',
          ...styles?.content,
        }}
      >
        {!disableHeader && (
          <div
            className="ModalWrapper__content-head"
            style={display ? { display: 'none' } : { ...styles?.header }}
          >
            <div
              className="ModalWrapper__content-head-title"
              style={{ ...styles?.header_title }}
            >
              {title}
            </div>
            <div
              className="ModalWrapper-close"
              onClick={handleClose}
              style={{ ...styles?.header_close_btn }}
            >
              <i
                className="icon-close size-icon-2 color-grey"
                style={{ ...styles?.header_close_btn_icon }}
              ></i>
            </div>
          </div>
        )}

        {children}
      </div>
    </div>,
    document.body,
  )
}

export default ModalWrapper
