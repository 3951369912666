import { memo, useState } from "react";
import ReactDOM from "react-dom";
import GoogleMapReact from "google-map-react";
import config from "../../config";
import Geocode from "react-geocode";
import MapMarker from "components/MapMarker";
import MapType from "components/MapType";
import Location from "components/Location";

const defaultProps = {
  center: {
    lat: 41.619549,
    lng: -93.598022,
  },
  zoom: 13,
};

const GeoSelect = ({ toggleShow, address = {}, setAddress }) => {
  //config geo map
  Geocode.setApiKey(config.GOOGLEMAPKEY);
  Geocode.setLanguage("en");
  Geocode.setRegion("us");

  const [map, setMap] = useState(null);
  const [mapType, setMapType] = useState(
    window.localStorage.getItem("mapType") || "satellite"
  );
  const [location, setLocation] = useState(
    Object.keys(address)?.length ? address : {}
  );
  const [zoom, setZoom] = useState(location?.zoom || 17);
  const addMarker = ({ lat, lng, event }) => {
    if (event.target.classList.length) return;

    Geocode.fromLatLng(lat, lng).then(
      (response) => {
        const address = response.results[0].formatted_address;
        setLocation({
          address: address,
          center: {
            lat: lat,
            lng: lng,
          },
          zoom: 17,
        });
      },
      (error) => {
        console.error(error);
      }
    );
  };

  return ReactDOM.createPortal(
    <div className="geo-select">
      <div className="geo-select-wrapper hide-tile">
        <GoogleMapReact
          bootstrapURLKeys={{ key: config.GOOGLEMAPKEY, libraries: "places" }}
          onGoogleApiLoaded={({ map, maps }) => setMap(map)}
          onClick={addMarker}
          defaultCenter={defaultProps.center}
          defaultZoom={defaultProps.zoom}
          center={location?.center}
          zoom={zoom}
          options={() => {
            return {
              mapTypeId: mapType,
              zoomControl: false,
              fullscreenControl: false,
              rotateControl: false,
            };
          }}
        >
          {location?.center && (
            <MapMarker
              lat={location.center?.lat}
              lng={location.center?.lng}
              onDelete={() => {
                setLocation({});
                // setMarker(null)
              }}
            />
          )}
        </GoogleMapReact>
        {map && (
          <Location
            setLocation={setLocation}
            location={location}
            user={true}
          />
        )}
        <div className="map-tools-bottom">
          <div className="map-tools__group">
            <div
              className="map-tools__btn"
              onClick={() => {
                if (zoom < 20) {
                  setZoom(zoom + 1);
                }
              }}
            >
              <span class="icon-math-plus size-icon-2 color-green"></span>
            </div>
            <div
              className="map-tools__btn"
              onClick={() => {
                if (zoom > 4) {
                  setZoom(zoom - 1);
                }
              }}
            >
              <span class="icon-math-minus size-icon-2 color-green"></span>
            </div>
          </div>
        </div>
        <div className="map-tools-top">
          <div
            className="main-btn mr main-btn-top"
            onClick={() => {
              toggleShow(false);
            }}
          >
            <span className="icon-close color-white" />
          </div>
        </div>
        <div className="home-page__new-mission-action">
          <div
            className="main-btn mr red-style"
            onClick={() => {
              toggleShow(false);
            }}
          >
            Cancel <span className="icon-close color-red ml" />
          </div>
          <button
            type="button"
            className={`main-btn ${Object.keys(location)?.length ? "" : "disabled"
              }`}
            disabled={!Object.keys(location)?.length}
            onClick={() => {
              toggleShow(false);
              setAddress(location.address);
            }}
          >
            Save <span className="icon-save color-white ml" />
          </button>
        </div>

        <MapType mapType={mapType} setMapType={setMapType} />
      </div>
    </div>,
    document.body
  );
};

export default memo(GeoSelect);
