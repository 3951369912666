import React, { createContext, useReducer } from 'react';

const initialBtnState = {
  info: false,
  mod: 'HDOrthoScan',
  upload: '',
};

const reducer = (state, action) => {
  switch (action.type) {
    case 'TOGGLEINFO': {
      return {
        ...state,
        info: !state.info,
      };
    }
    case 'SETMOD': {
      const { mod } = action.payload;
      return {
        ...state,
        mod: mod,
      };
    }
    case 'SETUPLOAD': {
      return {
        ...state,
        upload: action.payload,
      };
    }
    default: {
      return state;
    }
  }
};

const DataBtnContext = createContext(initialBtnState);

export const DataBtnProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialBtnState);

  const toggleInfo = () => {
    dispatch({
      type: 'TOGGLEINFO',
    });
  };

  const setMod = mod => {
    dispatch({
      type: 'SETMOD',
      payload: {
        mod: mod,
      },
    });
  };

  const setUpload = id => {
    dispatch({
      type: 'SETUPLOAD',
      payload: id,
    });
  };

  return (
    <DataBtnContext.Provider
      value={{
        ...state,
        toggleInfo,
        setMod,
        setUpload,
      }}
    >
      {children}
    </DataBtnContext.Provider>
  );
};

export default DataBtnContext;
