import React, { useState } from "react";
import PropTypes from "prop-types";
import useAuth from "hooks/useAuth";
import NavBar from "./NavBar";
import Confirm from "components/Confirm";
import { useParams } from "react-router-dom";

const MainLayout = ({ children }) => {
  const { logout } = useAuth();
  const [confirm, setConfirm] = useState(false);
  const { token } = useParams();

  return (
    <>
      <div className="main-panel">
        {!!!token && <NavBar setConfirm={setConfirm} />}
        <div className="children-wrapper">
          {children}
          <div id="children-wrapper-portal" />
          <div id="box-portal" />
        </div>
      </div>
      {confirm && (
        <Confirm
          logout
          setConfirm={setConfirm}
          action={logout}
          title="Log out"
          text="Are you sure you want to log out?"
        />
      )}
      <div id="modal-portal" />
    </>
  );
};

MainLayout.propTypes = {
  children: PropTypes.node,
};

export default MainLayout;
