const config = {
  api_host: "https://dev.api.infrascan.us",
  // api_host: "http://localhost:3117",
  libraries: ["places", "drawing", "geometry"],
  support: "contact@infralytiks.com",
  sampleHeightUrl:
    "https://2rqfb2o6f4.execute-api.us-east-1.amazonaws.com/default/secure-vda-rasterio-2",
  sampleHeightAPIKey: "fPLdnNXcLw847NyHVmv2J7fDnMYeUtIvaHTlyI3v",
};

export default config;
