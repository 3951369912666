import React from 'react'
import PropTypes from 'prop-types'
import NavBar from './NavBar'

const ShareLayout = ({ children }) => {
  return (
    <>
      <div className="main-panel">
        <div className="children-wrapper">
          {children}
          <div id="children-wrapper-portal" />
        </div>
      </div>
      <div id="modal-portal" />
    </>
  )
}

ShareLayout.propTypes = {
  children: PropTypes.node,
}

export default ShareLayout
