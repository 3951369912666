import React from 'react';
import PropTypes from 'prop-types';
import DatePickerReact, { registerLocale } from 'react-datepicker';
import enUS from 'date-fns/locale/en-US';
import Header from './Components/Header';
import CustomInput from './Components/CustomInput';
registerLocale('en-us', enUS);

const DatePicker = ({
  max,
  min,
  preview,
  state,
  place = 'right-start',
  disabled = false,
  onChange = () => {},
  customInput = <CustomInput />,
}) => {
  return (
    <div className={`DatePicker ${preview ? 'previewBtn' : ''}`}>
      <DatePickerReact
        selected={state || ''}
        onChange={date => onChange(date)}
        locale="en-us"
        selectsStart
        disabled={disabled}
        renderCustomHeader={Header}
        customInput={customInput}
        popperPlacement={place}
        minDate={min || ''}
        maxDate={max || ''}
      />
    </div>
  );
};

DatePicker.protoType = {
  state: PropTypes.string,
  onChange: PropTypes.func,
  clearBtn: PropTypes.bool,
};

export default DatePicker;
