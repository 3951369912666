import React from "react";

const Copyright = () => {
  return (
    <div className="copyright">
      <ul>
        <li>
            <a href="https://infralytiks.com/privacy.html" target="_blank" rel="noreferrer">Privacy</a>
        </li>
        <li>
            <a href="https://infralytiks.com/disclaimer.html" target="_blank" rel="noreferrer">Disclaimer</a>
        </li>
        <li>
            <a href="https://infralytiks.com/terms.html" target="_blank" rel="noreferrer">Terms of use</a>
        </li>
      </ul>
      <p>Copyright© 2022 InfraDrone, LLC dba InfraLytiks</p>
    </div>
  );
};

export default Copyright;
