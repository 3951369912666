import axios from 'axios';

const axiosInstance = axios.create();

// axiosInstance.interceptors.response.use(
//   response => response,
//   error =>{
//     console.log('sddsds');
//     // if(error.response?.status === 401){
//     //   localStorage.removeItem('accessToken');
//     //   localStorage.removeItem('mission_filter');
//     //   // window.location.reload();
//     // }
//     Promise.reject(
//       (error.response && error.response.data) || 'Something went wrong',
//     )
//     }
// );

export default axiosInstance;
