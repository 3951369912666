import React, { forwardRef } from "react";

const CustomInput = forwardRef(({ value, onClick }, ref) => (
  <div className="custom-input" onClick={onClick}>
    <button
      type="button"
      className={`custom-input-field ${value ? "active" : ""}`}
    >
      <span
        style={{
          color: "#8A91A1",
          fontSize: "14px",
          lineHeight: "18px",
        }}
      >
        {value || "MM/dd/yyyy"}
      </span>
      {/* <div className={`icon-calendar ml color-dark-grey`} ref={ref} /> */}
    </button>
  </div>
));

export default CustomInput;
