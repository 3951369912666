import React, { useEffect, useState } from 'react'
import ReactDOM from 'react-dom'
import portalModalConfirm from 'components/ConfirmPortal'
import LoadingScreen from 'components/LoadingScreen'
import NoAvatar from 'assets/no-avatar.png'
import userCreatePortal from './UserCreatePortal'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

import Select from 'react-select'
const customStyles = {
  control: (base) => ({
    ...base,
    boxSizing: 'border-box',
    height: 34,
    borderRadius: 8,
    // boxShadow: 'none',
    fontSize: 12,
    // color: '#626874',
    color: 'var(--title-color)',
    backgroundColor: '#e7ecf4',

    border: '1px solid #cccccc',
    cursor: 'pointer',
    //boxShadow: "var(--shadow-6)",
    // filter:
    //   'drop-shadow(-4px -4px 8px #FBFDFF) drop-shadow(4px 4px 8px #DEE3EB)',
  }),
  indicatorSeparator: (base) => ({
    ...base,
    display: 'none',
  }),
  container: (base) => ({
    ...base,
    // color: '#626874',
    color: 'var(--desc-color)',
    width: 150,
    height: 34,
  }),
  menu: (base) => ({
    ...base,
    background: 'var(--bg-color-dark)',
  }),
  option: (base, { data, isDisabled, isFocused, isSelected }) => ({
    ...base,
    fontSize: 14,
    color: isSelected ? 'var(--border-color)' : 'var(--desc-color)',
    backgroundColor: isSelected
      ? 'var(--main-color)'
      : isFocused
      ? 'var(--main-color-hover-light)'
      : 'var(--bg-color-dark)',
    cursor: 'pointer',
    ':active': {
      ...base[':active'],
      backgroundColor: 'var(--main-color)',
      color: 'white',
    },
  }),
  singleValue: (base) => ({
    ...base,
    // color: '#79808E',
    color: 'var(--title-color) !important',
    fontSize: 14,
  }),
}
const roleListOption = [
  {
    label: 'Super Admin',
    value: 'superAdmin',
  },
  {
    label: 'Team Admin',
    value: 'companyAdmin',
  },
  {
    label: 'User',
    value: 'user',
  },
]
const userInfoPortal = ({
  user,
  company,
  companies,
  view,
  setCheckClickOpacityInfoUser,
  rmUser,
  deleteFromCompany,
  updUser,
  initialise,
  scans,
  history,
  users,
  changeRole,
  isMyProfile,
  location,
  currentUser,
}) => {
  ReactDOM.render(
    <UserInfoPortal
      close={() => {
        if (setCheckClickOpacityInfoUser) {
          setCheckClickOpacityInfoUser(false)
        }
        ReactDOM.unmountComponentAtNode(document.getElementById('modal-portal'))
      }}
      user={user}
      company={company}
      companies={companies}
      view={view}
      rmUser={rmUser}
      deleteFromCompany={deleteFromCompany}
      updUser={updUser}
      initialise={initialise}
      scans={scans}
      history={history}
      users={users}
      setCheckClickOpacityInfoUser={setCheckClickOpacityInfoUser}
      changeRole={changeRole}
      isMyProfile={isMyProfile}
      location={location}
      currentUser={currentUser}
    />,
    document.getElementById('modal-portal'),
  )
}

const formatDataOfbirth = (stringDate) => {
  stringDate = new Date(stringDate).toLocaleDateString()
  return stringDate
}

export const UserInfoPortal = ({
  close,
  user,
  company,
  companies,
  view,
  rmUser,
  deleteFromCompany,
  updUser,
  initialise,
  scans,
  history,
  users,
  setCheckClickOpacityInfoUser,
  changeRole,
  isMyProfile,
  location,
  currentUser,
}) => {
  const [activeStatus, setActiveStatus] = useState('active')
  const [loading, setLoading] = useState(false)
  const [changePassword, setChangePassword] = useState(false)
  const notify = (data) =>
    toast.error(data, {
      position: 'top-right',
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: false,
      draggable: true,
      progress: undefined,
    })
  const [data, setData] = useState({
    email: user.email,
    firstName: user.firstName,
    lastName: user.lastName,
    username: user.username,
    birth: user.birth,
    phone: user.phone,
    address: user.address,
    timeZone: user?.timeZone || 'CST',
    status: user.status,
  })

  useEffect(() => {
    setActiveStatus(user?.status)
  }, [user])
  const rm = async (id) => {
    setLoading(true)
    await rmUser(id)
    setLoading(false)
  }
  const rmFromCompany = async (id) => {
    setLoading(true)
    await deleteFromCompany(id)
    setLoading(false)
  }

  const role = async (id, role) => {
    setLoading(true)
    await changeRole(id, role)
    setLoading(false)
    close()
  }

  const changeData = (field, value) => {
    setData((prev) => ({
      ...prev,
      [field]: value,
    }))
  }

  const submit = async () => {
    const { password, newPassword, confirmPassword } = data

    if (password || newPassword) {
      if (newPassword !== confirmPassword) {
        notify('Passwords do not match')
        return
      }
      if ((newPassword?.length || 0) < 6) {
        notify('Please enter a password that is 6 characters or more')
        return
      }
    }

    const formData = new FormData()

    for (const key in data) {
      if (!!data[key]) {
        if (key !== 'address') {
          formData.append(key, data[key])
        } else {
          formData.append(key, JSON.stringify(data[key]))
        }
      }
    }

    try {
      setLoading(true)
      await updUser(formData, user._id)
      setData((prev) => ({
        ...prev,
        password: undefined,
        newPassword: undefined,
        confirmPassword: undefined,
      }))
      setChangePassword(false)
      setLoading(false)
    } catch (error) {
      notify(error.message)
      setLoading(false)
    }
  }
  return (
    <>
      <ToastContainer />
      <div
        className="dashBoard-userInfo"
        style={view === 'view' ? { zIndex: 999999 } : {}}
      >
        <div className="dashBoard-userInfo-main">
          <div>
            <div className="dashBoard-userInfo-status">
              <div
                className={`dashBoard-userInfo-status-active ${
                  activeStatus === 'active' ? 'active' : ''
                }`}
              >
                <div></div>
                <p>Active</p>
              </div>
              <div
                className={`dashBoard-userInfo-status-inactive ${
                  activeStatus === 'inactive' ? 'active' : ''
                }`}
              >
                <div></div>
                <p>Inactive</p>
              </div>
            </div>
            <div className="dashBoard-userInfo-action">
              {view === 'view' ? (
                <></>
              ) : (
                !isMyProfile && (
                  <div
                    onClick={() => {
                      portalModalConfirm(
                        'Warning',
                        true,
                        `Do you want to delete user ${user?.firstName} ${user?.lastName}?`,
                        () => {
                          rm(user._id)
                        },
                        false,
                        true,
                      )
                    }}
                  >
                    <span className="icon icon-trash"></span>
                  </div>
                )
              )}
              <div onClick={close}>
                <span className="icon icon-close"></span>
              </div>
            </div>
          </div>
          <div>
            <div>
              <div className="dashBoard-userInfo-avatar">
                <div>
                  <img
                    src={
                      !user?.avatar?.includes('s3.amazonaws.com')
                        ? NoAvatar
                        : user.avatar
                    }
                    alt="avatar"
                  />
                </div>
                <div
                  className="dashBoard-userInfo-btn-view"
                  onClick={
                    location?.pathname?.includes('scan')
                      ? () => {
                          ReactDOM.unmountComponentAtNode(
                            document.getElementById('modal-portal'),
                          )

                          history.push(`/scans?${user._id}`)
                        }
                      : () => {
                          history.push(`/scans?${user._id}`)
                        }
                  }
                >
                  <p>View Scans ({scans?.length})</p>
                </div>
              </div>
              <div className="dashBoard-userInfo-desc">
                <p>{`${user.firstName || ''} ${user.lastName || ''}`}</p>
                <p>{`${user.email || ''}`}</p>
              </div>
            </div>
            {view === 'view' ||
            (currentUser?.role.toLowerCase() === 'companyadmin' &&
              user?.role.toLowerCase() === 'superadmin') ? (
              <div></div>
            ) : (
              <div>
                <div
                  onClick={() => {
                    close()
                    userCreatePortal(
                      true,
                      () => {},
                      companies,
                      user,
                      users,
                      updUser,
                      changeRole,
                      currentUser,
                    )
                  }}
                  className={`${
                    changePassword
                      ? 'dashBoard-userInfo-btn-edit disable-btn-edit'
                      : 'dashBoard-userInfo-btn-edit'
                  }`}
                >
                  <p>{isMyProfile ? 'Edit My Profile' : 'Edit User Profile'}</p>
                </div>
                <div
                  className="dashBoard-userInfo-btn-pass"
                  onClick={() => setChangePassword(true)}
                >
                  <p>Change Password</p>
                </div>
              </div>
            )}
          </div>
        </div>
        {!changePassword ? (
          <>
            <div className="dashBoard-userInfo-team">
              <div className="dashBoard-userInfo-team-header">
                <p className="dashBoard-userInfo-title">Team</p>
                {company && (
                  <p
                    className="remove-user"
                    onClick={() => {
                      portalModalConfirm(
                        'Warning',
                        'Deleting user from company!',
                        `After confirmation, the user "${
                          user.firstName || user.lastName
                        }" will be deleted from company ${company.name}.`,
                        () => {
                          rmFromCompany(user._id)
                        },
                        false,
                      )
                    }}
                  >
                    Remove user from team
                  </p>
                )}
              </div>
              {company ? (
                <>
                  <div className="dashBoard-userInfo-team-company">
                    <div>
                      <div className="userInfo-team-company-avt">
                        <img
                          src={company.logo || NoAvatar}
                          alt="#"
                          onError={(e) => {
                            e.target.onError = null
                            e.target.src = NoAvatar
                          }}
                        />
                      </div>
                      <p className="white-space-name-company">{company.name}</p>
                    </div>
                    {/* <div className="userInfo-team-company-list-sort"> */}

                    {/* <p>User</p>
                        <span className="icon icon-carret-down size-icon-3"></span> */}
                    <Select
                      placeholder="Select user role"
                      options={
                        currentUser && currentUser.role === 'companyAdmin'
                          ? roleListOption.filter(
                              (el) =>
                                el.value.toLowerCase() !==
                                'superAdmin'.toLowerCase(),
                            )
                          : role && role === 'companyAdmin'
                          ? roleListOption.filter(
                              (el) =>
                                el.value.toLowerCase() !==
                                'superAdmin'.toLowerCase(),
                            )
                          : roleListOption
                      }
                      isSearchable={false}
                      isDisabled={(() => {
                        if (
                          currentUser?.role.toLowerCase() === 'companyadmin' &&
                          user?.role.toLowerCase() === 'superadmin'
                        ) {
                          return true
                        }
                        if (currentUser._id === user._id) {
                          return true
                        }
                        if (currentUser?.role.toLowerCase() === 'user') {
                          return true
                        }
                      })()}
                      styles={customStyles}
                      theme={(theme) => ({
                        ...theme,
                        borderRadius: 0,
                        colors: {
                          ...theme.colors,
                          primary: '#E2E5EB',
                          neutral30: '#E2E5EB',
                        },
                      })}
                      value={[
                        { value: 'superAdmin', label: 'Super Admin' },
                        { value: 'companyAdmin', label: 'Team Admin' },
                        { value: 'user', label: 'User' },
                      ].find((item) => item.value === user.role)}
                      onChange={(e) => {
                        if (currentUser._id === user._id) {
                          portalModalConfirm(
                            'Warning',
                            'Changing your own role!',
                            'After confirming this action, you will lose some of the rights',
                            () => {
                              role(user._id, e.value)
                            },
                            false,
                          )
                        } else {
                          role(user._id, e.value)
                        }
                      }}
                    />

                    {/* <ul></ul> */}
                    {/* </div> */}
                  </div>
                  <div className="dashBoard-userInfo-team-footer">
                    <p>Active since {company.createdAt || '05.21.2022'}</p>
                  </div>
                </>
              ) : (
                <>
                  <div className="dashBoard-userInfo-team-footer">
                    <p>
                      Team is not selected. To create user - please select team.
                    </p>
                  </div>
                </>
              )}
            </div>
            <div className="dashBoard-userInfo-other">
              <div className="dashBoard-userInfo-other-header">
                <p>Other Information</p>
              </div>
              <div className="dashBoard-userInfo-other-center">
                <div className="dashBoard-userInfo-other-item">
                  <p>Phone number</p>
                  <p>{user.phone || 'No data'}</p>
                </div>
                <div className="dashBoard-userInfo-other-item">
                  <p>Date of birth</p>
                  <p>
                    {(user.birth && formatDataOfbirth(user.birth)) || 'No data'}
                  </p>
                </div>
              </div>
              <div className="dashBoard-userInfo-other-bottom">
                <div className="dashBoard-userInfo-other-item">
                  <p>Address</p>
                  <p>{user.address || 'No data'}</p>
                </div>
              </div>
            </div>
          </>
        ) : (
          <div className="dashBoard-userInfo-changePw">
            <div>
              <div className="dashBoard-userInfo-changePw-title">
                <p>Change password</p>
              </div>
              {currentUser.role.toLowerCase() !== 'superadmin' && (
                <div className="dashBoard-userInfo-changePw-input">
                  <label htmlFor="userInfo-currentPw">
                    Current password: :
                  </label>
                  <input
                    type="password"
                    id="userInfo-currentPw"
                    onChange={(e) => {
                      changeData('password', e.target.value)
                    }}
                    autoComplete="new-password"
                  />
                </div>
              )}
              <div className="dashBoard-userInfo-changePw-input">
                <label htmlFor="userInfo-newPw">New Password: :</label>
                <input
                  type="password"
                  id="userInfo-newPw"
                  onChange={(e) => {
                    changeData('newPassword', e.target.value)
                  }}
                  autoComplete="new-password"
                />
              </div>
              <div className="dashBoard-userInfo-changePw-input">
                <label htmlFor="userInfo-confirmPw">
                  Confirm new password: :
                </label>
                <input
                  type="password"
                  id="userInfo-confirmPw"
                  onChange={(e) => {
                    changeData('confirmPassword', e.target.value)
                  }}
                  autoComplete="new-password"
                />
              </div>
            </div>
            <div className="dashBoard-userInfo-changePw-action">
              <div
                className="dashBoard-userInfo-changePw-save"
                onClick={submit}
              >
                <p>Save</p>
              </div>
              <div
                className="dashBoard-userInfo-changePw-cancel"
                onClick={() => setChangePassword(false)}
              >
                <p>Cancel</p>
              </div>
            </div>
          </div>
        )}
      </div>
      <div
        className="dashBoard-userInfo-wrapper"
        style={setCheckClickOpacityInfoUser ? { zIndex: 100000 } : {}}
        onClick={close}
      ></div>
      {loading && <LoadingScreen />}
    </>
  )
}

export default userInfoPortal
