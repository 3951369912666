import React from "react";
import { ReactComponent as Decor } from "assets/login-decor.svg";
import { ReactComponent as BgRectTop } from "assets/bg-rect-top.svg";
import { ReactComponent as BgRectBottom } from "assets/bg-rect-bottom.svg";
// import { ReactComponent as Logo } from "../../assets/logo.svg";
import logoInfraScan from "../../assets/InfraScanLogo_Black.png";
import Copyright from "components/Copyright";

const AuthLayout = ({ children }) => {
  return (
    <div className="authorization-layout">
      <div className="authorization-layout__decor">
        <BgRectTop className="bg-rect-top" />
        <Decor style={{ position: "relative", zIndex: "2" }} />
        <BgRectBottom className="bg-rect-bottom" />
      </div>
      <div className="authorization-layout__content">
        <div className="auth-wrapper">
          {/* <Logo className="logo" /> */}
          <div className="auth-wrapper-logo">
            <img
              src={logoInfraScan}
              alt="infraScan logo"
              className="auth-wrapper-logo-logoInfraScan"
            />
            <p className="auth-wrapper-logo-forgov">for gov</p>
          </div>
          {children}
          <Copyright />
        </div>
      </div>
    </div>
  );
};

export default AuthLayout;
