import React from 'react';
import ReactDOM from 'react-dom';
import Confirm from './Confirm';

const portalModalConfirm = (head, title, text, action, alert, deleteUser, ok, logout) => {
  ReactDOM.render(
    <Confirm
      setConfirm={() => {
        ReactDOM.unmountComponentAtNode(
          document.getElementById('modal-portal'),
        );
      }}
      action={() => {
        action();
        ReactDOM.unmountComponentAtNode(
          document.getElementById('modal-portal'),
        );
      }}
      head={head}
      title={title}
      text={text}
      notification={alert}
      ok={ok}
      logout={logout}
      deleteUser={deleteUser}
    />,
    document.getElementById('modal-portal'),
  );
};

export default portalModalConfirm;
