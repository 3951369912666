import { Router } from "react-router-dom";
import routes, { renderRoutes } from "./routes";
import { createBrowserHistory } from "history";

import "./styles/index.scss";
import { AuthProvider } from "contexts/JWTAuthContext";
import { ScansProvider } from "contexts/ScansContext";
import { DataBtnProvider } from "contexts/DataBtnContext";

export const history = createBrowserHistory();

const App = () => {
  return (<>
    {history?.location?.pathname?.includes('webView') ? <div id="box-portal"></div> : null}
    <AuthProvider>
      <ScansProvider>
        <DataBtnProvider>
          <Router history={history}>{renderRoutes(routes)}</Router>
        </DataBtnProvider>
      </ScansProvider>
    </AuthProvider>
  </>
  );
};

export default App;
