import React from "react";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete";

const Location = ({
  setStep = () => {},
  setLocation = () => {},
  location,
  setAddPin = () => {},
  setMarker = () => {},
  setZoom = () => {},
  user = false,
}) => {
  const handleChange = (address) => {
    setLocation({
      ...location,
      address: address,
    });
  };

  const handleSelect = (address) => {
    geocodeByAddress(address)
      .then((results) => getLatLng(results[0]))
      .then((latLng) => {
        setLocation((loc) => {
          return {
            address: address,
            center: {
              lat: latLng.lat,
              lng: latLng.lng,
            },
            zoom: "17",
          };
        });
        setZoom(17);

        setMarker({
          center: {
            lat: latLng.lat,
            lng: latLng.lng,
          },
        });

        setAddPin(true);
        setStep("new");
      })
      .catch((error) => console.error("Error", error));
  };

  return (
    <div className="mission-location-form">
      <div className="mission-location-form__input">
        <div className="custom-input">
          <PlacesAutocomplete
            value={location?.address || ""}
            onChange={handleChange}
            onError={(err) => {
              console.log(err);
            }}
            onSelect={handleSelect}
          >
            {({
              getInputProps,
              suggestions,
              getSuggestionItemProps,
              loading,
            }) => (
              <>
                <span className="icon-search search-icon color-grey" />
                <input
                  placeholder="Type Adress or location name..."
                  {...getInputProps({
                    className:
                      suggestions.length > 0
                        ? "custom-input-field location-search-input-drop"
                        : "custom-input-field",
                  })}
                />
                <div className="autocomplete-project-location">
                  {suggestions.map((suggestion, index) => (
                    <div {...getSuggestionItemProps(suggestion)} key={index}>
                      <span>
                        {" "}
                        <i className="pon-icon icon-pin size-icon-3 color-main" />
                        {suggestion.description}
                      </span>
                    </div>
                  ))}
                </div>
              </>
            )}
          </PlacesAutocomplete>
        </div>
      </div>
    </div>
  );
};

export default Location;
