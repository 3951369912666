import React from "react";
import { Redirect } from "react-router-dom";
import PropTypes from "prop-types";
import useAuth from "hooks/useAuth";

const AdminGuard = ({ children }) => {
  const { isAuthenticated, user } = useAuth();

  if (!isAuthenticated) {
    return <Redirect to="/login" />;
  } else if (
    user?.role.toLowerCase() !== "companyadmin" &&
    user?.role.toLowerCase() !== "superadmin"
  ) {
    return <Redirect to="/" />;
  }

  return <>{children}</>;
};

AdminGuard.propTypes = {
  children: PropTypes.node,
};

export default AdminGuard;
