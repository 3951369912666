import React from 'react';
import Select from '../../Select';
import { getMonth, getYear } from 'date-fns';

function generateYears(start, end) {
  const list = [];
  for (let i = start; i < end + 1; i++) {
    list.push(i);
  }

  return list;
}

const months = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
].map(option => ({ value: option, label: option }));

const years = generateYears(1990, getYear(new Date())).map(option => ({
  value: option,
  label: option,
}));

const Header = ({ date, changeYear, changeMonth }) => (
  <div className="DatePicker__head">
    <div className="DatePicker__head-select">
      <Select
        options={months}
        value={months.find(_ => _.value === months[getMonth(date)]?.value)}
        onChange={selected => {
          changeMonth(months.findIndex(_ => _.value === selected.value))
        }}
      />
      <Select
        options={years}
        value={years.find(_ => _.value === getYear(date))}
        onChange={selected => changeYear(selected.value)}
      />
    </div>
  </div>
);

export default Header;
