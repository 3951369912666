import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useHistory } from "react-router-dom";
import LogoFull from "assets/logo_full.png";
import Logo from "assets/logo.png";
import useAuth from "hooks/useAuth";
import useScans from "hooks/useScans";
import config from "config";
import NoAvatar from "assets/no-avatar.png";
import ModalWrapper from "components/ModalWrapper";
import userInfoPortal from "components/UseInfoPortal";
import axios from "./../../../utils/axios";
import DatabaseIcon from "../../../assets/database.svg";
import LoadingScreen from "components/LoadingScreen";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const NavBar = ({ setConfirm }) => {
  const location = useLocation();
  const [access, setAccess] = useState("");
  const [navActiveItem, setNavActiveItem] = useState(
    location.pathname.substring(1)
  );
  const [isFullNav, setIsFullNav] = useState(false);
  const history = useHistory();
  const { user, scans, users, companies , updUser , changeRole, deleteFromCompany, initialise } = useAuth();
  const { selectScan, openScan, setOpenScan, currentScan } = useScans();
  const [openModalSupport, setOpenModalSupport] = useState(false);
  const [load, setLoad] = useState(false);
  const notify = (data , type) =>{
    if(type === "success"){
      return toast.success(data, {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
      });
    }
    if(type === "error"){
      return toast.error(data, {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
      });
    }
  }
  const [data, setData] = useState({
    email: user?.email,
    problem: null,
    descriptoin: null,
  });
  const changeData = (field, value) => {
    setData((prev) => ({
      ...prev,
      [field]: value,
    }));
  };
  const navList = [
    {
      index: 0,
      icon: "icon-sharp color-white",
      href: "/scans",
      title: "3D Scan",
      tooltipText: "3D Scans List",
    },
    {
      index: 1,
      href: "/admin",
      icon: "icon-users color-white",
      title: "Admin Dashboard",
      tooltipText: "Admin Dashboard",
      admin: true,
    },
    {
      index: 2,
      href: "/about",
      icon: "icon-document-info color-white",
      title: "About",
      tooltipText: "About",
    },
    {
      index: 3,
      href: "/tutorials",
      icon: "icon-education color-white",
      title: "Tutorials",
      tooltipText: "Tutorials",
    },
    {
      index: 4,
      href: "/support",
      icon: "icon-chat-support color-white",
      title: "Support",
      tooltipText: "Support",
    },
    {
      index: 5,
      href: "/term-and-condition",
      icon: "icon-document color-white",
      title: "Terms And Conditions",
      tooltipText: "Terms And Conditions",
    },
    {
      index: 6,
      href: "/policy",
      icon: "icon-keyhole color-white",
      title: "Privacy Policy",
      tooltipText: "Privacy Policy",
    },

  ];

  useEffect(() => {
    if (location.pathname.includes("data")) {
      setNavActiveItem("scans");
      return;
    } else {
      setNavActiveItem(location.pathname.substring(1));
    }
  }, [navActiveItem]);

  const handleClickNavItem = (section) => {
    if (section.href === "/data" && location.pathname.includes("data")) {
      setNavActiveItem("scans");
      return;
    }
    if (section.href === "/scans" && currentScan) {
      selectScan(null);
    }

    if (section.href === "/scans" && openScan) {
      setOpenScan(null);
    }

    if (section.href === "/support") {
      setOpenModalSupport(!openModalSupport);
      return false;
    }
    if (section.href !== "/data") {
      if(section.href === "/policy"){
        window.open('https://infralytiks.com/privacy.html', '_blank').focus();
      }
      else if(section.href === "/term-and-condition"){
        window.open("https://infralytiks.com/terms.html", '_blank').focus();
      }else
        history.push(section.href);
    } else {
      // const temp = currentScan || openScan || null;
      // if (
      //   !!temp?.status?.length &&
      //   temp.status[temp.status.length - 1].value === "Completed"
      // ) {
      //   return history.push(`/data/${temp._id}`);
      // }
      history.push("/scans");
      selectScan(null);
      setOpenScan(null);
    }
  };
  const submitSendSupport = async (event) => {
    event.preventDefault();
    const { problem, descriptoin } = data;
    if (!problem) {
      notify("Problem field is required !" , "error");
      return;
    }
    if (!descriptoin) {
      notify("Descriptoin field is required !" , "error");
      return;
    }
    try {
      setLoad(true);
      await axios.post(`${config.api_host}/api/account/support`, data);
      notify(`Email sent successfully!` , "success")
    }
    catch (err) {
      notify(`Account with email ${user?.email} is not exist!` , "error");
    }
    finally {
      setLoad(false);
      setOpenModalSupport(false);
      setData({
        email: user?.email,
        problem: null,
        descriptoin: null,
      })
    }
  };
  useEffect(() => {
    if(companies){
      if(user && user.company){
        companies.map(item => {
          if(item._id === user.company._id){
            user.company.logo = item.logo;
          }
        })
      }
    }
  } , [companies])
  return (
    <div className={`nav-bar ${!isFullNav ? "minimize" : ""}`}>
      <ToastContainer />  
      <nav className="nav-content">
        <div className="nav-top">
          <div className="nav-logo" onClick={() => history.push("/")}>
            <div className="nav-hidden-logo"></div>
            <img
              src={isFullNav ? LogoFull : Logo}
              alt="logo"
              onError={(e) => {
                e.target.onError = null;
                e.target.src = isFullNav ? LogoFull : Logo;
              }}
            />
            <div className="for-gov">For Gov</div>
          </div>
          <div className="nav-card"
            onClick={() => {
                  userInfoPortal({
                    user,
                    company: user?.company,
                    companies,
                    view: true,
                    updUser,
                    isMyProfile: true,
                    scans: scans?.filter((el) => el.user === user?._id),
                    history,
                    users,
                    location,
                    currentUser: user,
                    changeRole,
                    deleteFromCompany,
                    initialise
                  });
                }}
          >
            <div>
              <div
                title="PROFILE"
                className="nav-card-avt"
              >
                {user?.avatar && (
                  <img
                    alt={`${user?.firstName || ""} ${user?.lastName || ""}`}
                    src={user.avatar}
                    onError={(e) => {
                      e.target.onError = null;
                      e.target.src = NoAvatar;
                    }}
                  />
                )}
              </div>
              {isFullNav && (
                <div className="nav-card-desc">
                  <p>{`${user?.firstName} ${user?.lastName}`}</p>
                  <p>{user?.email}</p>
                </div>
              )}
            </div>
            <span className="icon-chevron-right"></span>
          </div>
          <div className="nav-list scroll-wrapper">
            <ul>
              {navList.map((item) => {
                if (item.admin && user?.role === "user") return null;
                return (
                  <li
                    key={item.index}
                    onClick={() => {
                      item.title !== "Support" &&
                        setNavActiveItem(item.href.substring(1));
                      handleClickNavItem(item);
                    }}
                    className={`tooltip-btn ${
                      item && item.title !== "Support"
                        ? navActiveItem === item.href.substring(1)
                          ? "activeNavItem"
                          : ""
                        : openModalSupport
                        ? "activeNavItem"
                        : ""
                    }`}
                  >
                    <span className="tooltiptext tooltiptext-right">
                      {item.tooltipText}
                    </span>
                    <span className={item.icon}></span>
                    <span
                      style={{ display: `${isFullNav ? "block" : "none"}` }}
                    >
                      {item.title}
                    </span>
                  </li>
                );
              })}
            </ul>
          </div>
        </div>
        <div className="nav-bottom">
          <div
            className="nav-minimize"
            onClick={() => setIsFullNav((prev) => !prev)}
          >
            {isFullNav ? (
              <>
                <span className="icon-arrow-left color-white size-icon-3"></span>
                <span>Minimize menu</span>
              </>
            ) : (
              <span className="icon-arrow-right color-white size-icon-3"></span>
            )}
          </div>
          <div className="divider"></div>
          <div
            className="nav-logout"
            onClick={() => {
              setConfirm(true);
            }}
          >
            <span className="nav-icon size-3 icon-log-out color-red size-icon-3" />
            {isFullNav && <span>Log out</span>}
          </div>
        </div>
      </nav>

      <ModalWrapper
        title="Access denied"
        toggleShow={() => {
          setAccess("");
        }}
        show={!!access}
        width={400}
      >
        <div className="access-wrapper">
          <div className="access-left">
            <span className={`nav-icon size-icon-5 icon-danger color-red`} />
          </div>
          <div className="access-right">
            <div className="access-title">
              You don’t have access to VDA {access}!
            </div>
            <div className="access-text">
              Sorry, you don’t have access to VDA {access} Functions. To request
              access, please email
              <a href={`mailto:${config.support}`}>{config.support}</a>
            </div>
          </div>
        </div>

        <div className="modal-footer">
          <div
            className="modal-footer-btn"
            onClick={() => {
              setAccess("");
            }}
          >
            <span>Cancel</span>
            <i className="icon-close size-icon-3 color-gray" />
          </div>
          <div
            className="modal-footer-btn active"
            onClick={() => {
              navigator.clipboard.writeText(config.support);
              setAccess("");
            }}
          >
            <span>Copy email</span>
            <i className="icon-save size-icon-3 color-white" />
          </div>
        </div>
      </ModalWrapper>
      <ModalWrapper
        title="support"
        toggleShow={setOpenModalSupport}
        show={openModalSupport}
        styles={{
          header: {
            height: "56px",
            backgroundColor: "#FFFBFF",
            borderRadius: "16px 16px 0px 0px",
            padding: "8px",
          },
          header_title: {
            paddingLeft: "8px",
            color: "#454F53",
            fontWeight: "500",
          },
          header_close_btn: {
            width: "40px",
            height: "40px",
            background:
              "linear-gradient(135.37deg, rgba(0, 0, 0, 0.4) 4.29%, rgba(255, 255, 255, 0.4) 95.6%), #E7ECF4",
            backgroundBlendMode: "soft-light, normal",
            border: "1px solid rgba(255, 255, 255, 0.4)",
            boxShadow:
              "-9px -9px 12px rgba(250, 251, 255, 0.35), 9px 9px 12px rgba(166, 171, 189, 0.25)",
            borderRadius: "8px",
          },
          header_close_btn_icon: {
            width: "10px",
            height: "10px",
            color: "#000000",
          },
          content: {
            borderRadius: "16px 16px 16px 16px",
          },
        }}
        width={500}
      >
        {load ? (
            <LoadingScreen
            />
          ) : null}
        <div className="support_content">
          <div className="support_content-default support_content-problem">
            <label>Problem:</label>
            <input onChange={(e) => {
                    changeData("problem", e.target.value);
                  }}
            type="text" placeholder="Problem title..." />
          </div>
          <div className="support_content-default support_content-description">
            <label>Description:</label>
            <textarea
              name=""
              id=""
              cols="30"
              rows="5"
              placeholder="Enter description"
              onChange={(e) => {
                changeData("descriptoin", e.target.value);
              }}
            ></textarea>
          </div>
          <div className="support_content-seperate"></div>
          <div className="support_content-btn">
            <button
              className="support_content-btn-cancel"
              onClick={() => {
                setOpenModalSupport(false);
              }}
            >
              Cancel
              <i className="icon-close"></i>
            </button>
            <button className="support_content-btn-send" onClick={submitSendSupport}>
              Send
              <i className="icon-mail-open"></i>
            </button>
          </div>
        </div>
      </ModalWrapper>
    </div>
  );
};

export default NavBar;
