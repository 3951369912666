export const JWTAuthConstants = {
  INITIALISE: "INITIALISE",
  ADDUSER: "ADDUSER",
  UPDUSER: "UPDUSER",
  RMUSER: "RMUSER",
  LOGIN: "LOGIN",
  LOGOUT: "LOGOUT",
  REGISTER: "REGISTER",
  RMCOMPANY: "RMCOMPANY",
  ADDTOCPMPANY: "ADDTOCPMPANY",
  ADDCOMPANY: "ADDCOMPANY",
  UPDCOMPANY: "UPDCOMPANY",
  DELETEFROMCOMPANY: "DELETEFROMCOMPANY",
};
