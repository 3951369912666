import React from "react";
import ReactDOM from "react-dom";
import NoLogo from "assets/box.png";

const LoadingScreen = ({ background, style = {}, logo }) => {
  return ReactDOM.createPortal(
    <div className={"loading-wrapper"} style={style}>
      <div>
        <div style={{ position: "relative" }}>
          <div className="progress">
            <svg
              className="progress-circle"
              width="200px"
              height="200px"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle
                className="progress-circle-back"
                cx="90"
                cy="90"
                r="74"
              ></circle>
              <circle
                className="progress-circle-prog"
                cx="90"
                cy="90"
                r="74"
              ></circle>
            </svg>
            <div className="progress-text" data-progress="0">
              <img
                src={logo ? logo : NoLogo}
                alt="logo"
                onError={(e) => {
                  e.target.onError = null;
                  e.target.src = logo ? logo : NoLogo;
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </div>,
    document.body
  );
};

export default LoadingScreen;
