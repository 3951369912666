import React from 'react';

const MapType = ({ mapType, setMapType }) => {
  const types = [
    {
      type: 'roadmap',
      icon: <span className="icon-roadmap color-grey" />,
    },
    {
      type: 'satellite',
      icon: <span className="icon-satellite color-grey" />,
    },
    {
      type: 'hybrid',
      icon: <span className="icon-hybrid color-grey" />,
    },
  ];

  const selectType = type => () => {
    setMapType(type);
    window.localStorage.setItem('mapType', type);
  };

  return (
    <div className="map-type">
      {types.map(item => (
        <div
          key={item.type}
          className={`map-type-item ${
            mapType === item.type ? 'map-type-active' : ''
          }`}
          onClick={selectType(item.type)}
        >
          {item.icon}
        </div>
      ))}
    </div>
  );
};

export default MapType;
