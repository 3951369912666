import React from "react";
import ReactSelect from "react-select";

const customStyles = {
  control: (base, { isDisabled }) => ({
    ...base,
    boxSizing: "border-box",
    height: 34,
    borderRadius: 6,
    // boxShadow: 'none',
    fontSize: 12,
    color: isDisabled ? "#BCC1CB" : "var(--desc-color)",
    background: "#F7F8FA",
    cursor: "pointer",
  }),
  indicatorSeparator: (base) => ({
    ...base,
    display: "none",
  }),
  container: (base) => ({
    ...base,
    // color: '#626874',
    color: "var(--desc-color)",
    width: "100%",
    height: 34,
  }),
  menu: (base) => ({
    ...base,
  }),
  menuList: (base) => ({
    ...base,
    overflowX: "hidden",
    backgroundColor: "#ffffff",
  }),
  menuPortal: (base) => ({
    ...base,
    overflowX: "hidden",
  }),
  option: (base, { data, isDisabled, isFocused, isSelected }) => ({
    ...base,
    fontSize: 14,
    color: isSelected ? "var(--title-car)" : "var(--desc-color-light)",
    backgroundColor: isSelected
      ? "var(--main-color)"
      : isFocused
      ? "#ffffff"
      : "var(--bg-color-dark)",
    cursor: "pointer",
    ":active": {
      ...base[":active"],
      backgroundColor: "var(--main-color)",
      color: "white",
    },
  }),
  singleValue: (base) => ({
    ...base,
    // color: '#79808E',
    color: "var(--desc-color) !important",
    fontSize: 14,
  }),
  placeholder: (base) => ({
    ...base,
    // color: '#79808E',
    color: "#9BA1AD !important",
    // color: "var(--desc-color) !important",
    fontSize: 14,
  }),
};

const Select = ({
  label,
  required,
  placeholder,
  value,
  options,
  isMulti,
  isClearable,
  name,
  onChange,
  disabled,
  error,
  customFilter,
}) => {
  return (
    <div className="custom-input">
      {error && <span className="error-alert">{error}</span>}
      <ReactSelect
        placeholder={placeholder}
        value={value}
        options={options}
        isMulti={!!isMulti}
        styles={customFilter ? customFilter : customStyles}
        onChange={onChange}
        menuPlacement="auto"
        name={name}
        isSearchable={false}
        isDisabled={!!disabled}
        isClearable={isClearable}
        theme={(theme) => ({
          ...theme,
          borderRadius: 0,
          colors: {
            ...theme.colors,
            primary: "#E2E5EB",
            neutral30: "#E2E5EB",
          },
        })}
      />
      {label && (
        <label className="custom-input-label">
          {required ? (
            <>
              <span style={{ color: "red" }}>*</span>
              {label}
            </>
          ) : (
            label
          )}
        </label>
      )}
    </div>
  );
};

export default Select;
