import React, { useState, useEffect } from "react";
import ReactDOM from "react-dom";
import LoadingScreen from "components/LoadingScreen";
import DatePicker from "components/DateInput/DatePicker";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import NoAvatar from "../assets/no-avatar.png";
import GeoSelect from "./GeoSelect";
import Select from "./Select";

const customStyles = {
  control: (base) => ({
    ...base,
    boxSizing: "border-box",
    height: 42,
    width: "100%",
    borderRadius: 8,
    boxShadow: "none !important",
    minHeight: 42,
    fontSize: 13,
    color: "var(--desc-color)",
    backgroundColor: "var(--bg-color-dark)",
    cursor: "pointer",
    // filter:
    //   'drop-shadow(-4px -4px 8px #FBFDFF) drop-shadow(4px 4px 8px #DEE3EB)',
  }),
  indicatorSeparator: (base) => ({
    ...base,
    display: "none",
  }),
  container: (base) => ({
    ...base,
    color: "var(--desc-color",
    width: "100%",
    height: 48,
    borderRadius: 8,
    border: "none",
  }),
  menu: (base) => ({
    ...base,
    background: "white",
    boxShadow: "var(--shadow)",
    borderRadius: 8,
    padding: 0,
    border: "none",
  }),
  option: (base, { data, isDisabled, isFocused, isSelected }) => ({
    ...base,
    fontSize: 14,
    color: isSelected ? "white" : "var(--desc-color)",
    backgroundColor: isSelected
      ? "var(--main-color)"
      : isFocused
        ? "var(--bg-color-light)"
        : "white",
    cursor: "pointer",
    ":active": {
      ...base[":active"],
      backgroundColor: "var(--main-color)",
      color: "white",
    },
    borderRadius: 8,
  }),
  singleValue: (base) => ({
    ...base,
    color: "#333 !important",
    fontSize: 14,
  }),
  indicatorsContainer: (base) => ({
    ...base,
    padding: 0,
  }),
  placeholder: (base) => ({
    ...base,
    // color: '#79808E',
    color: "#9BA1AD !important",
    // color: "var(--desc-color) !important",
    fontSize: 14,
  }),
};

const userCreatePortal = (
  edit = false,
  addUser,
  companies,
  user = null,
  users,
  updUser,
  changeRole,
  currentUser
) => {
  if (user._id) {
    ReactDOM.render(
      <UserCreatePortal
        close={() => {
          ReactDOM.unmountComponentAtNode(
            document.getElementById("modal-portal")
          );
        }}
        edit={edit}
        addUser={addUser}
        companies={companies}
        user={user}
        users={users}
        updUser={updUser}
        changeRole={changeRole}
        currentUser={currentUser}
        oneComapny={currentUser}
      />,
      document.getElementById("modal-portal")
    );
  } else {
    ReactDOM.render(
      <UserCreatePortal
        close={() => {
          ReactDOM.unmountComponentAtNode(
            document.getElementById("modal-portal")
          );
        }}
        edit={edit}
        addUser={addUser}
        companies={companies}
        role={user}
        users={users}
        updUser={updUser}
        changeRole={changeRole}
        oneComapny={currentUser}
      />,
      document.getElementById("modal-portal")
    );
  }
};

const roleListOption = [
  {
    label: "Super Admin",
    value: "superAdmin",
  },
  {
    label: "Team Admin",
    value: "companyAdmin",
  },
  {
    label: "User",
    value: "user",
  },
];

export const UserCreatePortal = ({
  close,
  edit,
  addUser,
  companies,
  user,
  users,
  updUser,
  changeRole,
  role,
  currentUser,
  oneComapny
}) => {

  const [activeStatus, setActiveStatus] = useState("active");
  const [loading, setLoading] = useState(false);
  const [invalidType, setInvalidType] = useState(false);
  const [selectAddress, setSelectAddress] = useState(false);
  const [isOpenSelectCompany, setIsOpenSelectCompany] = useState(false);
  const [roleSelect, setRoleSelect] = useState();
  const [companySelect, setCompanySelect] = useState({
    name: companies?.find((x) => x._id === user?.company || x._id === user?.company?._id)?.name
      ? companies?.find((x) => x._id === user?.company || x._id === user?.company?._id)?.name
      : "",
    _id: user?.company ? user?.company : "",
  });
  const listCompanyData = () => {
    if (oneComapny?.role === "superAdmin") {
      return companies.map((item) => ({
        name: item.name,
        _id: item._id,
      }))
    } else if (oneComapny?.role === "companyAdmin") {
      return [{
        name: oneComapny?.company?.name,
        _id: oneComapny?.company?._id,
      }]
    }
  }
  const listCompany = listCompanyData()
  const [data, setData] = useState({
    avatar: undefined,
    firstName: user?.firstName ? user.firstName : "",
    lastName: user?.lastName ? user.lastName : "",
    email: user?.email ? user.email : "",
    password: "",
    username: user?.username ? user.username : "",
    birth: user?.birth ? user.birth : "",
    role: user?.role ? user.role : "",
    company: user?.company ? user?.company : "",
    unit: user?.unit ? user.unit : "",
    phone: user?.phone ? user.phone : "",
    address: user?.address ? user.address : "",
    status: user?.status ? user.status : "",
    cfpassword: "",
  });
  const notify = (data) =>
    toast.error(data, {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: false,
      draggable: true,
      progress: undefined,
    });
  const [fileSize, setFileSize] = useState(0);
  useEffect(() => {
    if (user) {
      setActiveStatus(user?.status);
    }
  }, [user]);
  const changeData = (field, value) => {
    setData((prev) => ({
      ...prev,
      [field]: value,
    }));
  };
  const setField = (field, value) => {
    setData((prev) => ({
      ...prev,
      [field]: value,
    }));
  };
  const checkUserName = (username, email) => {
    let listUserName = [];
    let listEmail = [];
    users.map((item) => {
      if (item.username) {
        listUserName.push(item.username);
      }
      if (item.email) {
        listEmail.push(item.email);
      }
    });
    if (listUserName.includes(username)) {
      return "User with this nickname is already registered";
    } else if (listEmail.includes(email)) {
      return "User with this e-mail is already registered";
    }
    return false;
  };
  const create = async () => {
    const required = {
      firstName: "First name",
      email: "Email",
      password: "Password",
      cfpassword: "Confirm Password",
      username: "User name",
    };

    if (
      !/^[A-Z0-9._%+-]+@[A-Z0-9-]+.+.[A-Z]{2,4}$/i.test(data.email) &&
      data["email"]
    ) {
      notify("Enter correct email: test@example.com");
      return;
    }
    for (const key in data) {
      if (!data[key] && required[key]) {
        notify(`${required[key]} field is required !`);
        return;
      }
      if(key === 'avatar' && !!!data[key]) {
        delete data[key];
      }
    }

    if (data.password?.length < 6) {
      notify("Please enter a password that is 6 characters or more");
      return;
    }

    if (data.password !== data.cfpassword) {
      notify("The entered passwords do not match. Try again.");

      return;
    }

    if (fileSize > 6291456) {
      notify("Max file size - 6 mb.");
      return;
    }
    const formData = new FormData();

    // const company = companyList.find((item) => item._id === data.company);

    for (const key in data) {
      if (data[key] !== "" && key !== "cfpassword") {
        formData.append(key, data[key]);
      }
    }
    if (activeStatus) {
      formData.append("status", activeStatus);
    }

    if (roleSelect) {
      formData.append("role", roleSelect.value);
    }
    try {
      setLoading(true);
      await addUser(formData);
      //   toggleShow(false);
      close();
      setLoading(false);
    } catch (e) {
      if (checkUserName(data.username, data.email)) {
        notify(checkUserName(data.username, data.email));
      } else {
        notify(e.message);
      }
      setLoading(false);
    }
  };
  useEffect(() => {
    if (edit && user && user.role) {
      switch (user.role) {
        case "companyAdmin": {
          setRoleSelect({
            label: "Team Admin",
            value: "companyAdmin",
          });
          break;
        }

        case "user": {
          setRoleSelect({
            label: "User",
            value: "user",
          });
          break;
        }

        case "superAdmin": {
          setRoleSelect({
            label: "Super Admin",
            value: "superAdmin",
          });
          break;
        }

        default:
          setRoleSelect({
            label: "",
            value: "",
          });
      }
    }
  }, [edit, user]);
  const update = async () => {
    const required = {
      firstName: "First name",
      email: "Email",
      username: "User name",
    };

    if (
      !/^[A-Z0-9._%+-]+@[A-Z0-9-]+.+.[A-Z]{2,4}$/i.test(data.email) &&
      data["email"]
    ) {
      notify("Enter correct email: test@example.com");
      return;
    }
    for (const key in data) {
      if (!data[key] && required[key]) {
        notify(`${required[key]} field is required !`);
        return;
      }
    }

    if (fileSize > 6291456) {
      notify("Max file size - 6 mb.");
      return;
    }

    const formData = new FormData();

    // const company = companyList.find((item) => item._id === data.company);

    for (const key in data) {
      if (
        data[key] !== "" &&
        key !== "cfpassword" &&
        key !== "password" &&
        key !== "status" && 
        key !== "company"
      ) {
        formData.append(key, data[key]);
      }
      if (data.company._id && key === "company" && data[key] !== "") {
        formData.append(key, data[key]._id);
      } else if(key === "company" && data[key] !== ""){
        formData.append(key, data[key]);
      }
      if (data.password.length > 0 && key === "password") {
        formData.append(key, data[key]);
      }
    }
    if (activeStatus) {
      formData.append("status", activeStatus);
    }
    try {
      setLoading(true);
      await updUser(formData, user._id);
      if (roleSelect) {
        await changeRole(user._id, roleSelect.value);
      }
      //   toggleShow(false);
      close();
      setLoading(false);
    } catch (e) {
      notify(e.message);
      setLoading(false);
    }
  };

  return (
    <>
      <ToastContainer />
      <form
        autoComplete="off"
        className="dashBoard-userCreate scroll-wrapper"
        style={selectAddress ? { opacity: 0.5 } : {}}
      >
        <div className="dashBoard-userCreate-main">
          <div>
            <div className="dashBoard-userCreate-status">
              <div
                className={`dashBoard-userCreate-status-active ${activeStatus === "active" ? "active" : ""
                  }`}
                onClick={() => setActiveStatus("active")}
              >
                <div></div>
                <p>Active</p>
              </div>
              <div
                className={`dashBoard-userCreate-status-inactive ${activeStatus === "inactive" ? "active" : ""
                  }`}
                onClick={() => setActiveStatus("inactive")}
              >
                <div></div>
                <p>Inactive</p>
              </div>
            </div>
            <div className="dashBoard-userCreate-close">
              <div onClick={close}>
                <span className="icon icon-close"></span>
              </div>
            </div>
          </div>
          <div>
            <div>
              <div className="dashBoard-userCreate-avatar">
                {edit ? (
                  <label htmlFor="uploadAvatar">
                    {/* <img src={(user && user.avatar) || NoAvatar} alt="#" /> */}
                    {data.avatar ? (
                      <img
                        src={
                          data.avatar
                            ? URL.createObjectURL(data.avatar)
                            : NoAvatar
                        }
                        alt="#"
                      />
                    ) : (
                      <img
                        src={
                            !user?.avatar?.includes("s3.amazonaws.com")
                            ? NoAvatar
                            : user.avatar
                        }
                        alt="avatar"
                      />
                      // <i className="icon-software-upload size-icon-5 color-grey" />
                    )}
                  </label>
                ) : (
                  <>
                    <label htmlFor="uploadAvatar">
                      {data.avatar ? (
                        <img
                          src={
                            data.avatar
                              ? URL.createObjectURL(data.avatar)
                              : NoAvatar
                          }
                          alt="#"
                        />
                      ) : (
                        <img src={(user && user.avatar) || NoAvatar} alt="#" />
                      )}
                    </label>
                  </>
                )}
                <input
                  style={{ display: "none" }}
                  type="file"
                  id="uploadAvatar"
                  onChange={(e) => {
                    const fileInputType = e.target.files[0]?.type;
                    setFileSize(e.target.files[0]?.size);
                    // Allowing file type
                    var allowedExtensions = ["jpg", "jpeg", "png"];

                    if (
                      !allowedExtensions.includes(fileInputType?.split("/")[1])
                    ) {
                      setInvalidType(true);
                      return false;
                    }
                    if (e.target.files[0]) {
                      if (e.target.files[0]?.size < 6291456) {
                        setField("avatar", e.target.files[0]);
                      }
                    }
                  }}
                  accept=".jpg,.jpeg,.png"
                />
                {invalidType && (
                  <div className="error-msg">Invalid file type</div>
                )}
              </div>
              <div className="dashBoard-userCreate-upload">
                <label htmlFor="uploadAvatar" id="uploadAvatar">
                  <p>Upload</p>
                </label>
                <p>{fileSize < 6291456 ? "" : "Max file size - 6 mb"}</p>
              </div>
            </div>
            <div>
              <div className="dashBoard-userCreate-name">
                <div className="dashBoard-userCreate-input">
                  <label htmlFor="userCreate-name">Name :</label>
                  <input
                    type="text"
                    id="userCreate-name"
                    placeholder="Enter your Name"
                    defaultValue={user?.firstName}
                    onChange={(e) => {
                      setField("firstName", e.target.value);
                    }}
                  />
                </div>
                <div className="dashBoard-userCreate-input">
                  <label htmlFor="userCreate-surName">Surname :</label>
                  <input
                    type="text"
                    id="userCreate-surName"
                    placeholder="Enter your Surname"
                    defaultValue={user?.lastName}
                    onChange={(e) => {
                      setField("lastName", e.target.value);
                    }}
                  />
                </div>
              </div>
              <div className="dashBoard-userCreate-email">
                <div className="dashBoard-userCreate-input">
                  <label htmlFor="userCreate-username">Username :</label>
                  <input
                    type="text"
                    id="userCreate-username"
                    placeholder="Enter your Username"
                    defaultValue={user?.username}
                    onChange={(e) => {
                      setField("username", e.target.value);
                    }}
                    autoComplete="off"
                  />
                </div>
                <div className="dashBoard-userCreate-input">
                  <label htmlFor="userCreate-email">Email :</label>
                  <input
                    type="text"
                    id="userCreate-email"
                    placeholder="Enter your Email"
                    defaultValue={user?.email}
                    onChange={(e) => {
                      setField("email", e.target.value);
                    }}
                  />
                </div>
                {edit ? undefined : (
                  <>
                    <div className="dashBoard-userCreate-input">
                      <label htmlFor="userCreate-password">Password :</label>
                      <input
                        type="password"
                        id="userCreate-password"
                        placeholder="Enter your Password"
                        onChange={(e) => {
                          setField("password", e.target.value);
                        }}
                        autoComplete="new-password"
                      />
                    </div>
                    <div className="dashBoard-userCreate-input">
                      <label htmlFor="userCreate-cfpassword">
                        Confirm Password:
                      </label>
                      <input
                        type="password"
                        id="userCreate-cfpassword"
                        placeholder="Enter your Password"
                        onChange={(e) => {
                          changeData("cfpassword", e.target.value);
                        }}
                      />
                    </div>
                  </>
                )}
              </div>
              <div className="dashBoard-userCreate-selectWrapper">
                <div className="dashBoard-userCreate-selectRole">
                  <Select
                    placeholder="User role"
                    options={
                      currentUser && currentUser.role === "companyAdmin"
                        ? roleListOption.filter(
                          (el) =>
                            el.value.toLowerCase() !== "superAdmin".toLowerCase()
                        )
                        : role && role === "companyAdmin" ? roleListOption.filter(
                          (el) =>
                            el.value.toLowerCase() !== "superAdmin".toLowerCase()
                        ) : roleListOption
                    }
                    isSearchable={false}
                    disabled={(() => {
                      if (currentUser?._id === user?._id && edit) {
                        return true;
                      }
                      return currentUser && currentUser.role === "user"
                        ? true
                        : role && role === "user" ? true : false
                    })()}
                    customFilter={customStyles}
                    value={roleSelect}
                    onChange={(e) => {
                      setRoleSelect(e);
                    }}
                  />
                </div>
                <div
                  className="dashBoard-userCreate-selectLocation main-btn"
                  onClick={() => setSelectAddress(true)}
                >
                  Select location
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="dashBoard-userCreate-team">
          <div className="dashBoard-userCreate-team-header">
            <p>Team</p>
          </div>
          <div className="dashBoard-userCreate-team-select">
            <div onClick={() => setIsOpenSelectCompany((prev) => !prev)}>
              <p className="white-space-name-company">{companySelect.name || "Select Team"}</p>
              <span className="icon icon-carret-down size-icon-3"></span>
            </div>
            <ul
              className={`selectCompany-options ${isOpenSelectCompany && "open"
                }`}
            >
              {listCompany.map((option) => (
                <li
                  className="selectCompany-option"
                  key={option?._id}
                  onClick={() => {
                    setField("company", option?._id);
                    setCompanySelect(option);
                    setIsOpenSelectCompany(false);
                  }}
                >
                  {option?.name}
                </li>
              ))}
            </ul>
          </div>
          {!companySelect._id && (
            <div className="dashBoard-userCreate-team-footer">
              <p>Team is not selected. To create user - please select team.</p>
            </div>
          )}
        </div>
        <div className="dashBoard-userCreate-other">
          <div className="dashBoard-userCreate-other-header">
            <p>Other Information</p>
          </div>
          <div className="dashBoard-userCreate-other-center">
            <div className="dashBoard-userCreate-input">
              <label htmlFor="userCreate-phone">Phone :</label>
              <input
                type="text"
                id="userCreate-phone"
                placeholder="Enter your Phone"
                defaultValue={user?.phone}
                onChange={(e) => {
                  setField("phone", e.target.value);
                }}
              />
            </div>
            <div className="dashBoard-userCreate-input">
              <label htmlFor="userCreate-birth">Date of birth :</label>
              <DatePicker
                state={data.birth ? new Date((new Date(data.birth))) : new Date()}
                onChange={(date) => setField("birth", new Date((new Date(date)).setHours(0,0,0,0)))}
                place="auto"
              />
            </div>
          </div>
          <div className="dashBoard-userCreate-other-bottom">
            <div className="dashBoard-userCreate-input">
              <label htmlFor="userCreate-address">Address :</label>
              <input
                type="text"
                id="userCreate-address"
                placeholder="Enter your address"
                defaultValue={user?.address}
                onChange={(e) => {
                  setField("address", e.target.value);
                }}
              />
            </div>
          </div>
        </div>
        <div className="dashBoard-userCreate-action">
          <div className="dashBoard-userCreate-action-cancel" onClick={close}>
            <p>Cancel</p>
            <span className="icon icon-close"></span>
          </div>
          {!edit ? (
            <div
              className="dashBoard-userCreate-action-create"
              onClick={create}
            >
              <p>Create User</p>
              <span className="icon icon-plus"></span>
            </div>
          ) : (
            <div className="dashBoard-userCreate-action-edit" onClick={update}>
              <p>Save</p>
              <span className="icon icon-save"></span>
            </div>
          )}
        </div>
      </form>
      <div className="dashBoard-userCreate-wrapper" onClick={close}></div>
      {loading && <LoadingScreen />}
      {selectAddress && (
        <GeoSelect
          toggleShow={setSelectAddress}
          address={data?.address}
          setAddress={(location) => {
            setField("address", location);
            document.getElementById("userCreate-address").value = location;
          }}
        />
      )}
    </>
  );
};

export default userCreatePortal;
