import React from "react";

const Confirm = ({
  logout,
  setConfirm,
  action,
  head,
  title,
  text,
  notification,
  ok,
  deleteUser,
}) => {
  const cancel = () => {
    if (notification) {
      action();
    }
    setConfirm(false);
  };
  const isCustomText = typeof text !== "string";
  return (
    <>
      <div className={`log-out-confirm ${deleteUser ? "delete-confirm" : ""}`}>
        {!deleteUser && (
          <div className="log-out-header">
            <div>{head}</div>
            <div className="cart-btn size-btn-l" onClick={cancel}>
              <span className="icon-close color-grey" />
            </div>
          </div>
        )}
        <div
          className={`log-out-confirm-msg ${
            deleteUser && "delete-confirm-msg"
          }`}
          style={{
            maxWidth: isCustomText ? text.maxWidth : "100%",
            textAlign: logout ? "start" : "",
          }}
        >
          {logout && (
            <div
              style={{ backgroundColor: ok ? "#95EEB9" : "" }}
              className="log-out-confirm-icon"
            >
              <span
                style={{ width: 20, height: 20 }}
                className={
                  ok ? "icon-check-o color-jewel" : "icon-info color-grey"
                }
              />
            </div>
          )}
          {deleteUser && <span className="icon icon-warn size-icon-8"></span>}
          <div>
            <strong>{title}</strong>
            <p>{isCustomText ? text.content : text}</p>
          </div>
        </div>
        <div className={`button-group ${deleteUser && "delete-button-group"}`}>
          {!notification && (
            <div className="main-btn clear mr" onClick={cancel}>
              Cancel <span className="icon-close ml" />
            </div>
          )}
          <div
            className={`main-btn ${text?.delete ? "red-bg" : ""}`}
            onClick={() => {
              notification ? cancel() : action();
            }}
          >
            {deleteUser ? (
              <>
                <span>Delete</span>
                <span
                  style={{ width: "11px" }}
                  className="icon-trash color-white ml-s"
                />
              </>
            ) : isCustomText ? (
              text.btnYes
            ) : (
              <>
                {ok ? "Ok" : "Yes"}{" "}
                <span
                  style={{ width: "11px" }}
                  className="icon-check color-white ml-s"
                />
              </>
            )}
          </div>
        </div>
      </div>
      <div
        className={`log-out-confirm-wrapper ${
          deleteUser && "delete-confirm-wrapper"
        }`}
        onClick={cancel}
      />
    </>
  );
};

export default Confirm;
