import axios from 'axios';
import useAuth from 'hooks/useAuth'
import { createContext, useReducer, useEffect } from 'react'
import axiosInstance from 'utils/axios'
import config from '../config';

const initialScansState = {
  scans: null,
  currentScan: null,
  openScan: null,
  data: null,
}

const reducer = (state, action) => {
  switch (action.type) {
    case 'INITIALISE': {
      const { scans } = action.payload

      return {
        ...state,
        scans,
      }
    }

    // Selecting the current mission
    case 'SELECTISSION': {
      const { scan } = action.payload
      return {
        ...state,
        currentMission: scan,
      }
    }

    case 'OPENMISSION': {
      const { scan } = action.payload
      return {
        ...state,
        openMission: scan,
      }
    }
    case 'SETDATA': {
      const { data } = action.payload
      return {
        ...state,
        data,
      }
    }
    case 'RMSCAN': {
      const { scan } = action.payload
      return {
        ...state,
        scans: state.scans.filter((item) => item._id !== scan._id),
      }
    }

    default:
      return state
  }
}

const ScansContext = createContext(initialScansState)

export const ScansProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialScansState)
  const { scans, isAuthenticated } = useAuth()

  const selectScan = (scan) => {
    dispatch({
      type: 'SELECTISSION',
      payload: {
        scan: scan,
      },
    })
  }

  const setOpenScan = (scan) => {
    dispatch({
      type: 'OPENMISSION',
      payload: {
        scan: scan,
      },
    })
  }

  const setData = (data) => {
    dispatch({
      type: 'SETDATA',
      payload: {
        data,
      },
    })
  }

  const rmScan = async (scan) => {
    try {
      await axios.delete(`${config.api_host}/api/scan/${scan._id}`);

      dispatch({
        type: 'RMSCAN',
        payload: {
          scan,
        },
      })
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    if (!isAuthenticated) return;
    const initialise = async () => {
      dispatch({
        type: 'INITIALISE',
        payload: {
          scans,
        },
      })
    }

    dispatch({
      type: 'INITIALISE',
      payload: {
        scans,
      },
    })

    initialise()
  }, [scans, isAuthenticated])

  return (
    <ScansContext.Provider
      value={{
        ...state,
        selectScan,
        setOpenScan,
        setData,
        rmScan,
      }}
    >
      {children}
    </ScansContext.Provider>
  )
}

export default ScansContext
