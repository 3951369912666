import MainLayout from "layouts/MainLayout";
import { Fragment, lazy, Suspense } from "react";
import { Route, Redirect, Switch } from "react-router-dom";
import LoadingScreen from "./components/LoadingScreen";
import AuthGuard from "components/Guard/AuthGuard";
import GuestGuard from "components/Guard/GuestGuard";
import AdminGuard from "components/Guard/AdminGuard";
import AuthLayout from "layouts/AuthLayout";
import ShareLayout from "layouts/ShareLayout";

export const renderRoutes = (routes = []) => (
  <Suspense fallback={<LoadingScreen />}>
    <Switch>
      {routes.map((route, i) => {
        const Guard = route.guard || Fragment;
        const Layout = route.layout || Fragment;
        const Component = route.component;

        return (
          <Route
            key={i}
            path={route.path}
            exact={route.exact}
            render={(props) => (
              <Guard>
                <Layout>
                  {route.routes ? (
                    renderRoutes(route.routes)
                  ) : (
                    <Component {...props} />
                  )}
                </Layout>
              </Guard>
            )}
          />
        );
      })}
    </Switch>
  </Suspense>
);

const routes = [
  {
    exact: true,
    path: "/404",
    component: lazy(() => import("views/errors")),
  },
  {
    exact: true,
    guard: GuestGuard,
    path: "/login",
    layout: AuthLayout,
    component: lazy(() => import("views/auth/LoginView")),
  },
  {
    exact: true,
    guard: GuestGuard,
    path: "/register",
    layout: AuthLayout,
    component: lazy(() => import("views/auth/RegisterView")),
  },
  {
    exact: true,
    guard: GuestGuard,
    path: "/forgot",
    layout: AuthLayout,
    component: lazy(() => import("views/auth/ForgotPassword")),
  },
  {
    exact: true,
    guard: GuestGuard,
    path: "/reset/:token",
    layout: AuthLayout,
    component: lazy(() => import("views/auth/ResetPassword")),
  },
  {
    path: "/profile/:profile",
    guard: AuthGuard,
    layout: MainLayout,
    component: lazy(() => import("views/admin/Profile")),
  },
  {
    path: "/admin",
    guard: AdminGuard,
    layout: MainLayout,
    routes: [
      {
        exact: true,
        path: "/admin",
        component: lazy(() => import("views/admin/Dashboard")),
      },
    ],
  },
  {
    layout: MainLayout,
    guard: AuthGuard,
    path: "/scans",
    component: lazy(() => import("views/scans")),
  },
  {
    layout: MainLayout,
    path: "/data/:scanId",
    guard: AuthGuard,
    component: lazy(() => import("views/data")),
  },
  {
    path: "/webView/:scanId/:token",
    component: lazy(() => import("views/data")),
  },
  {
    path: '/share/:shareId',
    layout: ShareLayout,
    component: lazy(() => import('views/data')),
  },
  // about
  {
    layout: MainLayout,
    guard: AuthGuard,
    path: "/about",
    component: lazy(() => import("views/about")),
  },

  //tutorials

  {
    layout: MainLayout,
    guard: AuthGuard,
    path: "/tutorials",
    component: lazy(() => import("views/tutorials")),
  },

  {
    path: "*",
    routes: [
      {
        exact: true,
        path: "/",
        component: () => <Redirect to="/scans" />,
      },
      {
        component: () => <Redirect to="/404" />,
      },
    ],
  },
];

export default routes;
