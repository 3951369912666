import React from 'react';
import { status } from 'helpers/status';

const MapMarker = ({ mission, onClick = () => {}, onDelete }) => {
  const missionStatus = !!mission
    ? mission?.versions?.[mission.versions.length - 1]?.status?.slice(-1).pop()
        .value || 'Not flown'
    : 'New';
  return (
    <div className="map-marker">
      <div className="map-marker__content" onClick={onClick}>
        <span
          className={`status status-size-m no-box-shadow ${status[missionStatus]}`}
        />
        {mission?.name &&
          <div className="marker-popup">
              [{mission?.name}]
          </div>
        }
        {onDelete && (
          <div className="map-marker__delete" onClick={onDelete}>
            <span className="icon-close color-white size-icon-1" />
          </div>
        )}
      </div>
    </div>
  );
};

export default MapMarker;
